.nav{
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 10vh;
  margin: 1em;
}

.nav-logo{
  height: 100%
}

.menu-button{
  border: none;
  background: none;
  font-size: 200%;
  color: #3B3937;
  cursor: pointer;
  z-index: 1;
}

.dropdown{
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(244, 244, 244, 0.55); /* Transparent background */
  backdrop-filter: blur(10px);
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  text-align: right;
}

.nav-location{
  background: none;
  font-size: 1.5em;
  padding: 1em;
  letter-spacing: 0.8em;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #3B3937;
}

.toggle-off{
  margin: 0.3em 0.74em;
}

@media screen and (max-width: 400px){
  .dropdown{
    font-size: 0.7em;
  }

  .nav{
    height: 5vh;
    font-size: 0.6em;
  }
}