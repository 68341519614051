.home{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.home-logo{
  height: 58vh;
}

.home-buttons{
  margin: 9vh;
  width: 100%;
  display: flex;
  justify-content: space-around;
}

@media screen and (max-width: 1000px){
  .home-logo{
    height: 50vw
  }
}

@media screen and (max-width: 800px){
  .home-buttons{
    flex-direction: column;
  }

  .home-button{
    margin: 1.4em;
    font-size: 0.6em;
  }
}