.details-container{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.details{
  display: flex;
  flex-direction: column;
  width: 55%;
  align-items: center;
  margin: 3em;
}

#details-gif{
  box-shadow: -0.6em 0.6em #3B3937;
}

.links-container{
  background: none;
  margin-top: 3em;
  width: 100%;
  display: flex;
  justify-content: space-around;
}

.detail-button{
  border: none;
}

.detail-section-header{
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1em;
  color: #3B3937;
  background: none;
  border-bottom: 1px solid #3B3937;
  text-align: center;
  letter-spacing: 0.8em;
  padding-bottom: 0.3em;
  width: 100%;
  margin-top: 2em;
}

.detail-section-header h2 {
  padding: 0 1em
}

.detail-img-container{
  width: 90%;
}

.detail-img{
  width: 100%;
  margin-top: 3em;
  box-shadow: -0.6em 0.6em #3B3937;
}

@media screen and (max-width: 900px){
  .links-container{
    flex-direction: column;
  }

  .detail-button{
    margin: 1em;
    font-size: 0.5em;
  }
}