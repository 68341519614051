.contact{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.email-container{
  display: flex;
  margin-top: 3em;
  font-size: 1.5em;
  align-items: center;
  text-align: center;
}

.link-container{
  display: flex;
  font-size: 1.2em;
}

.link-container a {
  text-decoration: none;
  color: #3B3937;
  margin: 3em
}

@media screen and (max-width: 600px){
  .link-container{
    flex-direction: column;
  }
}