.page-header{
  position: relative;
  background: #BEAB96;
  border-bottom: #3B3937 solid 0.8em;
  width: 55%;
  display: flex;
  align-items: center; 
}

.page-header h1{
  background: none;
  letter-spacing: 0.8em;
  font-size: 1.7em;
  padding: 0.6em;
  margin: 0;
  max-width: 70%;
}

#flower-3{
  position: absolute;
  background: none;
  height: 17vw;
  left: 70%;
}

@media screen and (max-width: 1000px){
  .page-header {
    width: 100%; 
  }
  
  #flower-3 {
    height: 30vw;
    bottom: -100%
  }
}

@media screen and (max-width: 600px){
  .page-header h1{
    font-size: 1em
  }
}