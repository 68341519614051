.about{
  width: 98vw
}

.header{
  margin-top: 3em;
  position: relative;
}

.about h1 {
  letter-spacing: 0.8em;
  color: #3B3937;
  background: #BEAB96;
  text-align: center;
  font-size: 1.3em;
  padding: 0.6em;
  margin: 0;
}

.about h2 {
  letter-spacing: 0.8em;
  color: #f4f4f4;
  background: #3B3937;
  text-align: center;
  font-size: 1em;
  padding: 0.6em;
  margin: 0;
}

.headshot{
  height: 100%;
  box-shadow: -0.6em 0.6em #3B3937;
  margin-top: 1em;
}

.about-info{
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 2.4em 7em;
  height: 18em;
}

.bio-and-buttons{
  width: 55%;
  font-size: 1.2em;
  margin-left: 3em;
}

.about-buttons{
  display: flex;
  justify-content: space-around;
  margin-top: 3em;
}

.about-button{
  font-size: 0.5em;
  margin: 1em;
}

.flower-img{
  position: absolute;
  background: none;
  height: 180%;
}

#flower-1{
  bottom: -38%;
  left: 1.5%;
}

#flower-2{
  bottom: -36%;
  left: 85.5%;
}


@media screen and (max-width: 1200px){
  #flower-1{
    display: none
  }

  #flower-2{
    bottom: -36%;
    left: 83%;
  }

  .about h1{
    font-size: 1.1em;
    text-align: left;
  }

  .about h2{
    font-size: 0.9em;
    text-align: left;
  }

  .headshot{
    height: 100%;
    margin-bottom: 2em;
  }

  .about-info{
    flex-direction: column;
    margin: 0;
    margin: 16em 0;
    align-items: center;
  }

  .bio-and-buttons{
    width: 80%;
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .about-buttons{
    flex-direction: column;
  }
}

@media screen and (max-width: 870px){
  #flower-2{
    bottom: -36%;
    left: 70%;
  }

  .about h1{
    font-size: 0.7em;
    text-align: left;
  }

  .about h2{
    font-size: 0.5em;
    text-align: left;
  }
}