
*{
  font-family: 'Oswald', sans-serif;
  background: #F4F4F4;
}

.app{
  position: relative
}

.button{
  text-decoration: none;
  color: #3B3937;
  background: #BEAB96;
  box-shadow: -0.6em 0.6em #3B3937;
  padding: 0.7em 2em;
  letter-spacing: 0.8em;
  font-size: 80%;
  text-align: center;
}